import { Button, ButtonClassKey, ButtonProps, makeStyles, PropTypes } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { createElement, useRef, VFC } from "react";
import { OPACITY_PULSE } from "../AppTheme";
import { UIAction } from "../types/uiActions";
import {
  renderUIAction,
  UIActionElementOnClickHandler,
  UIActionElementPostActionHandler,
  useUIAction,
} from "../utils/uiActions";
import { Link } from "./Link";

const useStyles = makeStyles(
  (theme) => ({
    ...OPACITY_PULSE.keyframes,
    root: {},
    rootLink: {
      "&:hover": {
        textDecoration: "none",
      },
    },
    thinking: {
      ...OPACITY_PULSE.elementStyles,
    },
    destructive: {
      color: theme.palette.error.main,
    },
  }),
  {
    classNamePrefix: "UIActionButton",
  }
);

export type UIActionButtonJSSClassKey = ButtonClassKey & keyof ReturnType<typeof useStyles>;

export type UIActionButtonProps = Omit<ButtonProps, "onClick" | "children" | "classes"> & {
  classes?: Partial<ClassNameMap<UIActionButtonJSSClassKey>>;
  uiAction: UIAction;
  onClick?: UIActionElementOnClickHandler;
  postAction?: UIActionElementPostActionHandler;
};

export const UIActionButton: VFC<UIActionButtonProps> = ({
  className,
  uiAction,
  classes: extClasses,
  onClick,
  postAction,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const ref = useRef<HTMLButtonElement>(null);
  const { handleClick, thinking } = useUIAction(uiAction, ref, onClick, postAction);

  return renderUIAction("button", uiAction, ({ actionObject, isLink }) => {
    const muiColor: PropTypes.Color | undefined = actionObject.color === "destructive" ? undefined : actionObject.color;

    return createElement(
      Button,
      {
        ref,
        startIcon: actionObject.icon,
        className: clsx(className, classes.root, {
          [classes.destructive]: actionObject.color === "destructive",
          [classes.thinking]: thinking,
          [classes.rootLink]: isLink,
        }),
        disabled: actionObject.disabled,
        ...rest,
        ...(muiColor && {
          color: muiColor,
        }),
        ...(isLink
          ? ({
              component: Link,
              href: actionObject.action,
              target: actionObject.target,
            } as Partial<ButtonProps>)
          : {
              onClick: handleClick,
            }),
      },
      actionObject.label
    );
  });
};
